import React from 'react'

import { Helmet } from 'react-helmet'
import ReactJotformEmbed from 'react-jotform-embed'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Kingly Solutions</title>
        <meta property="og:title" content="Landing Page" />
      </Helmet>
      <div data-role="Header" className="home-navbar-container">
        <div className="home-navbar">
          <h1>Kingly Solutions</h1>
          <div className="home-left-side">
            <div className="home-links-container">
              <a href="#about" className="home-link">
                關於我們
              </a>
              <a href="#services" className="home-link">
                業務範疇
              </a>
              <a href="#contact" className="home-link1">
                聯絡我們
              </a>
            </div>
          </div>
          <div className="home-right-side">
            <a href="#contact" className="home-cta-btn anchor button">聯絡我們</a>
          </div>
        </div>
      </div>
      <div id="about" className="home-hero">
        <div className="home-content-container">
          <div className="home-hero-text">
            <h1 className="home-heading">
              <span>從概念到實踐</span>
              <br></br>
            </h1>
            <button className="home-cta-btn1 button">
              <span>
                <a href="#contact">查詢服務</a>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div id="services" className="home-features">
        <br />
        <br />
        <div className="home-heading-container">
          <h2 className="home-text06 section-Heading">
            <span>提供資訊科技服務</span>
            <br></br>
            <span>實踐您的概念</span>
            <br></br>
          </h2>
        </div>
        <div className="home-cards-container">
          <div className="home-features-card">
            <div className="home-icon-container1">
            <img src="/assets/images/website.svg" className="home-icon" />
            </div>
            <div className="home-text-container">
              <span className="home-heading1 card-Heading">網頁設計</span>
              <span className="home-text11">
                為中小企業及商鋪而設，務求令客戶有效利用網上推廣產品及服務，帶來無限商機。服務包括網頁設計、網上商店、行動網站設計、搜尋器優化SEO服務、網站分析服務、電郵行銷服務及網上廣告等。
              </span>
            </div>
          </div>
          <div className="home-features-card1">
            <div className="home-icon-container1">
              <img src="/assets/images/mobile-app.svg" className="home-icon" />
            </div>
            <div className="home-text-container1">
              <span className="home-heading2 card-Heading">手機APP開發 </span>
              <span className="home-text12">
                <span>
                  隨著手機日漸普及和流行，手機APP已經成為大中小企業不可或缺的市場推廣營銷及客戶互動的工具。本公司提供一對一的手機APP開發服務，能準確了解閣下核心需求，開發出精準解決核心業務需求的手機APP。
                </span>
                <br className="card-Text"></br>
              </span>
            </div>
          </div>
          <div className="home-features-card2">
            <div className="home-icon-container1">
              <img src="/assets/images/cloud.svg" className="home-icon" />
            </div>
            <div className="home-text-container1">
              <span className="home-heading3 card-Heading">雲端技術諮詢</span>
              <span className="home-text15">
                您還在自架伺服器嗎？硬件維護成本又貴又麻煩嗎？本公司提供諮詢服務，助您把伺服器轉移到雲端，零硬件零維護成本實現可靠網站。
              </span>
            </div>
          </div>
          <div className="home-features-card3">
            <div className="home-icon-container1">
              <img src="/assets/images/robot.svg" className="home-icon" />
            </div>
            <div className="home-text-container3">
              <span className="home-heading4 card-Heading">
                流程自動化顧問服務
              </span>
              <span className="home-text16">
                <span>公司業務流程又慢又低效率嗎？本公司提供</span>
                <span>流程自動化顧問服務，助您利用資訊科技提升業務效率。</span>
              </span>
            </div>
          </div>
          <div className="home-features-card4">
            <div className="home-icon-container1">
              <img src="/assets/images/it-consult.svg" className="home-icon" style={{ marginLeft: 2, marginRight: -2 }} />
            </div>
            <div className="home-text-container4">
              <span className="home-heading5 card-Heading">
                資訊科技創業顧問
              </span>
              <span className="home-text19">
                您有想過創業，但因為覺得成本貴而卻步嗎？本公司能夠幫助您以低成本創業，助您早日實現理想。
              </span>
            </div>
          </div>
          <div className="home-features-card5">
            <div className="home-icon-container1">
            <img src="/assets/images/voucher.svg" className="home-icon" />
            </div>
            <div className="home-text-container5">
              <span className="home-heading6 card-Heading">科技劵顧問服務</span>
              <span className="home-text20">
                香港政府致力推廣資訊科技應用﹐分別推出科技券計劃及中小企推廣基金。本公司能幫助您擬定方案，以達到各項政府資助項目的申領條件。
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section-separator"></div>
      <div id="contact" className="home-get-in-touch">
        <ReactJotformEmbed src="https://secure.jotform.com/222765362490459" />
      </div>
      <div className="contact-form-cover" />
      <div className="home-section-separator3"></div>
      <div className="home-footer-container">
        <div className="home-footer">
          <div className="home-social-links">
          </div>
          <div className="home-copyright-container">
          <span className="anchor">Kingly Solutions &nbsp;</span>
            <svg viewBox="0 0 1024 1024" className="home-icon34">
              <path d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM506 390q-80 0-80 116v12q0 116 80 116 30 0 50-17t20-43h76q0 50-44 88-42 36-102 36-80 0-122-48t-42-132v-12q0-82 40-128 48-54 124-54 66 0 104 38 42 42 42 98h-76q0-14-6-26-10-20-14-24-20-20-50-20z"></path>
            </svg>
            <span className="anchor">版權所有 2022</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
